import CallBackend from "./call-backend";

class User{
    firstName ="";
    lastName="";
    email="";
    password="";
}
class UserAPI{
    private static baseURL="/user"

    static async register(user : User){
        return await CallBackend.post(this.baseURL,user);
    }
}

export {User, UserAPI}