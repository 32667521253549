import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import User from "../views/User.vue";
import Register from "../components/Register.vue";
import Login from "../components/Login.vue";
import ForgotPassword from "../components/ForgotPassword.vue";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "user",
    component: User,
    children: [{
      path: "/",
      name: "login",
      component: Login,
    },
    {
      path: "/register",
      name: "register",
      component: Register,
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPassword,
    },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
