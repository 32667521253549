export default class CallBackend{
    static baseURL="https://survey.venky.us/myuuid/api";

    static async get(urlPath:string) : Promise<any>{
        if(urlPath && urlPath.length>0){
            urlPath=this.baseURL+urlPath;
        }
        const request = new Request(urlPath,
            {
                method: "GET",
                mode: "cors",
                cache: "default",
            }
        );
        return await this.call(request);
    }

    static async post(urlPath:string,obj:any) : Promise<any>{
        if(urlPath && urlPath.length>0){
            urlPath=this.baseURL+urlPath;
        }
        const myHeaders=new Headers();
        myHeaders.set("content-type","application/json");
        const request = new Request(urlPath,
            {
                method: "POST",
                cache: "no-cache",
                headers: myHeaders,
                body: JSON.stringify(obj)
            }
        );
        return await this.call(request);
    }

    private static async call(request: Request) {        
        let data = null;
        try {
            const res = await fetch(request);
            console.log("after fetch");
            this.sleep(300);
            data = await res.json();
            console.log("after json");
        } catch (error) {
            console.log(error);
        }
        return data;
    }

    static async sleep(sleeptime:number) {
        return await new Promise(resolve => setTimeout(resolve, sleeptime));
    }    
}