
import { User, UserAPI } from "@/store/api/user";
import { Vue } from "vue-class-component";


export default class Register extends Vue{
    user = new User();

    async handleLogin(){
        const data = await UserAPI.register(this.user);
        console.log(JSON.stringify(data));
    }
}
